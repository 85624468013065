'use client';

import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { AppRouterCacheProvider } from '@mui/material-nextjs/v13-appRouter';

import { theme } from '../../configs/appearance/theme/theme.config';

export const ThemeRegistry = ({ children }: { children: React.ReactNode }) => (
  <AppRouterCacheProvider>
    <ThemeProvider theme={theme('light')}>
      <CssBaseline /> {children}
    </ThemeProvider>
  </AppRouterCacheProvider>
);
