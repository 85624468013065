import type { TThemePalette } from './palette.type';

const sisal = '#d7cfbd';

export const LIGHT_PALETTE: TThemePalette = {
  mode: 'light',
  common: { black: '#000000', white: '#ffffff' },
  primary: {
    main: '#008A30',
    light: '#55B175',
    dark: '#005C20',
    contrastText: '#EBFAF0',
  },
  secondary: {
    main: '#898057',
    light: '#D9D5C4',
    dark: '#645D3F',
    contrastText: '#EDEBE3',
  },
  error: {
    main: '#BA1A1A',
    light: '#FFDAD6',
    dark: '#410002',
    contrastText: '#fff',
  },
  warning: {
    main: '#EBD14C',
    light: '#FFF8D6',
    dark: '#BDA627',
    contrastText: '#fff',
  },
  info: {
    main: '#0288D1',
    light: '#90DCFE',
    dark: '#014175',
    contrastText: '#fff',
  },
  success: {
    main: '#1FAD2A',
    light: '#C4FBC8',
    dark: '#07690F',
    contrastText: '#fff',
  },
  grey: {
    '50': '#FAFAFA',
    '100': '#F5F5F5',
    '200': '#E7E7E7',
    '300': '#D7D7D7',
    '400': '#BDBDBD',
    '500': '#9E9E9E',
    '600': '#757575',
    '700': '#616161',
    '800': '#424242',
    '900': '#212121',
    A100: '#F5F5F5',
    A200: '#E7E7E7',
    A400: '#BDBDBD',
    A700: '#616161',
  },
  contrastThreshold: 3,
  tonalOffset: 0.2,
  text: {
    primary: '#4a4739',
    secondary: '#1d1c16',
    disabled: 'rgba(0, 0, 0, 0.38)',
  },
  divider: '#a19b8d',
  background: { paper: '#ffffff', default: '#e7e7e7' },
  action: {
    active: 'rgba(0, 0, 0, 0.54)',
    hover: 'rgba(0, 0, 0, 0.04)',
    hoverOpacity: 0.04,
    selected: 'rgba(0, 0, 0, 0.08)',
    selectedOpacity: 0.08,
    disabled: 'rgba(0, 0, 0, 0.26)',
    disabledBackground: sisal,
    disabledOpacity: 0.38,
    focus: sisal,
    focusOpacity: 0.12,
    activatedOpacity: 0.12,
  },
};
