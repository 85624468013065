import type { TThemePalette } from './palette.type';

const armadillo = '#4a4739';

export const DARK_PALETTE: TThemePalette = {
  mode: 'dark',
  common: { black: '#000', white: '#fff' },
  primary: {
    main: '#008A30',
    light: '#55B175',
    dark: '#005C20',
    contrastText: '#EBFAF0',
  },
  secondary: {
    main: '#898057',
    light: '#D9D5C4',
    dark: '#645D3F',
    contrastText: '#EDEBE3',
  },
  error: {
    main: '#BA1A1A',
    light: '#FFDAD6',
    dark: '#410002',
    contrastText: '#fff',
  },
  warning: {
    main: '#EBD14C',
    light: '#FFF8D6',
    dark: '#BDA627',
    contrastText: '#fff',
  },
  info: {
    main: '#0288D1',
    light: '#90DCFE',
    dark: '#014175',
    contrastText: '#fff',
  },
  success: {
    main: '#1FAD2A',
    light: '#C4FBC8',
    dark: '#07690F',
    contrastText: '#fff',
  },
  grey: {
    '50': '#FAFAFA',
    '100': '#F5F5F5',
    '200': '#E7E7E7',
    '300': '#D7D7D7',
    '400': '#BDBDBD',
    '500': '#9E9E9E',
    '600': '#757575',
    '700': '#616161',
    '800': '#424242',
    '900': '#212121',
    A100: '#F5F5F5',
    A200: '#E7E7E7',
    A400: '#BDBDBD',
    A700: '#616161',
  },
  contrastThreshold: 3,
  tonalOffset: 0.2,
  text: {
    primary: '#fafafa',
    secondary: '#eeeeee',
    disabled: 'rgba(255, 255, 255, 0.5)',
  },
  divider: armadillo,
  background: { paper: '#1d1d1d', default: '#0f0f0f' },
  action: {
    active: '#fff',
    hover: 'rgba(255, 255, 255, 0.08)',
    hoverOpacity: 0.08,
    selected: 'rgba(255, 255, 255, 0.16)',
    selectedOpacity: 0.16,
    disabled: 'rgba(255, 255, 255, 0.3)',
    disabledBackground: armadillo,
    disabledOpacity: 0.38,
    focus: armadillo,
    focusOpacity: 0.12,
    activatedOpacity: 0.24,
  },
};
