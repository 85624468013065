import { createTheme } from '@mui/material/styles';

import { DARK_PALETTE } from './palette/darkPalette';
import { LIGHT_PALETTE } from './palette/lightPalette';

import type { Theme as ThemeMUI } from '@mui/material/styles';
import type { TThemeType } from './theme.config.type';

export const THEME_TYPE: Record<TThemeType, TThemeType> = {
  light: 'light',
  dark: 'dark',
  system: 'system',
};

export const theme = (mode: Exclude<TThemeType, 'system'>): ThemeMUI =>
  createTheme({
    palette: mode === 'light' ? LIGHT_PALETTE : DARK_PALETTE,
    shape: {
      borderRadius: 12,
    },
  });
